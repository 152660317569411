import { RECOMMENDED_OFFERS_NAME } from 'utils/constants';

/**
 * Extracts the offer ID from a given string.
 *
 * @param {string} stringToProcess - The string to extract the offer ID from.
 * @returns {string} The offer ID, or `undefined` if no match is found.
 */
export const getOfferIdFromString = (stringToProcess) => {
	const pattern = /\/offer\/([0-9a-f-]+)/;
	return pattern.exec(stringToProcess)?.[1];
};

/**
 * Returns an array of primary offer IDs and an array of other offer IDs based on the DOM elements on the page.
 * @param {Element} barrierPage - The barrier-page element.
 * @returns {{primaryOffersIds: Array<string>, otherOffersIds: Array<string>}} An object containing arrays of primary and other offer IDs.
 */
export const getOfferIdsDisplayedOnAPage = (barrierPage) => {
	const primaryOffersIds = [];
	let otherOffersIds;

	try {
		// Primary Offer Ids
		const radioButtons = barrierPage.querySelector('#offer-cards')?.querySelectorAll('input');

		if (radioButtons) {
			primaryOffersIds.push(
				...Array.from(radioButtons).map((node) => node.getAttribute('data-offer-id'))
			);
		} else {
			const chargeButton = barrierPage.querySelector('#charge-button');
			primaryOffersIds.push(getOfferIdFromString(chargeButton.getAttribute('href')));
		}

		// Other Offer Ids
		const recommendedOfferComponent = barrierPage.querySelector(
			`[data-component="${RECOMMENDED_OFFERS_NAME}"]`
		);
		const otherOffersButtons = Array.from(
			recommendedOfferComponent.querySelectorAll('a[id^="select-button-"]')
		);
		otherOffersIds = otherOffersButtons.reduce((ids, button) => {
			// We do not need to get the offerIds of the hidden buttons
			if (getComputedStyle(button).display !== 'none') {
				const offerId = getOfferIdFromString(button.getAttribute('href'));

				// filtering null offerIDs
				if (offerId) {
					ids.push(offerId);
				}
			}
			return ids;
		}, []);
	} catch (error) {
		// Fail silently here, will log error in the server side
	}

	return { primaryOffersIds, otherOffersIds };
};
