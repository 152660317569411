export const insertSubXScript = () => {
	// Create the Sub(x) script element
	const subXScriptElement = document.createElement('script');
	subXScriptElement.async = true;
	subXScriptElement.src = 'https://d2ip7iv1l4ergv.cloudfront.net/embed/widget/subxGDLV3.min.js';

	// Insert the script element before the first script tag in the document
	const firstScriptElement = document.getElementsByTagName('script')[0];
	firstScriptElement.parentNode.insertBefore(subXScriptElement, firstScriptElement);
};
