import session from 'next-session-client';

export const insertUserUuid = async () => {
	const sessionId = session.sessionId();

	if (sessionId) {
		const { uuid } = await session.uuid();
		// uuid is `undefined` if session isn't valid
		if (uuid) {
			// Get the element containing the app context
			const pageKitAppContextElement = document.getElementById('page-kit-app-context');

			// Get the text content of the app context element
			const pageKitAppContextTextContent = pageKitAppContextElement.textContent;

			// Parse the text content as JSON
			const pageKitAppContextObject = JSON.parse(pageKitAppContextTextContent);

			// Update the userUuid property
			pageKitAppContextObject.userUuid = uuid;

			// Update the element's text content with the modified object
			pageKitAppContextElement.textContent = JSON.stringify(pageKitAppContextObject);
		}
	}
};
