import readyState from 'ready-state';

import * as nTracking from '@financial-times/n-tracking';

import { getCommonFeatures } from '../helpers/commonFeatures';
import { getTrackingBarrierComponents } from '../helpers/getTrackingBarrierComponents';
import { addSubxClickEventListener, trackSubxImpression } from '../helpers/subxUtils';
import { getOfferIdsDisplayedOnAPage } from '../helpers/offersUtils';
import { insertUserUuid } from '../helpers/insertUserUuid';
import { insertSubXScript } from '../helpers/insertSubXScript';

readyState.dom.then(async () => {
	const { flags, trackingData, appContext } = getCommonFeatures();

	if (flags.get('oTracking')) {
		const pageViewContext = trackingData?.pageViewContext || {};
		// TODO: reuse getTrackingBarrierComponents logic for Subx events?
		const barrierComponents = getTrackingBarrierComponents(pageViewContext?.barrierType);
		const activeZephrTests = window.Zephr?.accessDetails?.testGroups || {};
		const barrierPage = document.getElementById('barrier-page');

		// tracking context will have offer ids displayed on a page
		const { primaryOffersIds, otherOffersIds } = getOfferIdsDisplayedOnAPage(barrierPage);

		// empty offer ids slots are filled by null
		let offersDisplayed = primaryOffersIds ?? [null];
		for (let i = 0; i < 3; i++) {
			offersDisplayed.push(otherOffersIds?.[i] ?? null);
		}
		nTracking.init({
			appContext: appContext,
			pageViewContext: {
				...pageViewContext,
				barrier_components: barrierComponents,
				offers: offersDisplayed,
				zephr_tests: { active_zephr_tests: activeZephrTests },
			},
		});
	}

	if (flags.get('enableSubX')) {
		const barrierPage = document.getElementById('barrier-page');

		try {
			await insertUserUuid();
		} catch (error) {
			// eslint-disable-next-line no-console
			console.error('Failed to add user UUID to the Pagekit app context');
		}

		// Inserting the script after the insertUserUuid to wait for it insert the user uuid and if the user have the valid session. to
		insertSubXScript();

		const { pageViewContext } = trackingData;
		const accessDecisionProvider = pageViewContext?.access_decision_provider ?? null;
		const contentClassifications = pageViewContext?.content?.classifications ?? [];
		const contentTopics = pageViewContext?.content?.topics ?? [];
		const userInfo = pageViewContext?.user;

		// Add event listener for charge-button click
		addSubxClickEventListener(
			barrierPage,
			accessDecisionProvider,
			contentClassifications,
			contentTopics,
			userInfo
		);

		// Track Impression event on SubX as page is loaded. It does not wait for the method to finish
		// because SubX script may take longer time to return required properties and do not want to block the page load.
		trackSubxImpression(
			barrierPage,
			accessDecisionProvider,
			contentClassifications,
			contentTopics,
			userInfo
		);
	}
});
