import * as pageKitAppContext from '@financial-times/dotcom-ui-app-context';
import * as pageKitFlags from '@financial-times/dotcom-ui-flags';
import * as pageKitLayout from '@financial-times/dotcom-ui-layout';

import { nMessagingClient } from '@financial-times/n-messaging-client/main-client';

// Common client script features
export const getCommonFeatures = () => {
	// Extract extra data from appContext (trackingData) and leave the rest as appContext
	const { trackingData, ...appContext } = pageKitAppContext.init()?.getAll();

	const flags = pageKitFlags.init();
	/**
	 * initialise the Page Kit layout components, e.g. the menu drawer and sticky heady
	 * @see https://github.com/Financial-Times/dotcom-page-kit/blob/HEAD/packages/dotcom-ui-layout
	 */
	pageKitLayout.init();
	/**
	 * Temporary measure during Consent Management Platform (CMP) development:
	 * To avoid double banners only render the old cookie banner when the new CMP is disabled
	 * Remove once the new CMP is live
	 */
	if (!flags.get('adsDisableInternalCMP')) {
		nMessagingClient.init();
	}

	return { appContext, flags, trackingData };
};
