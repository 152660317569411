import {
	ARTICLE_HEADER_HERO_OFFER_NAME,
	ARTICLE_HEADER_HERO_RADIO_OFFER_NAME,
	FALLBACK_BARRIER_NAME,
	HERO_OFFER_NAME,
	SUBSCRIPTIONS_OPTIONS_HEADER_NAME,
	SUBSCRIPTIONS_OPTIONS_NAME,
	UNLOCK_BANNER_NAME,
} from 'utils/constants';

/**
 * Retrieves tracking data from barrier page components and organizes it into an array of objects.
 *
 * @param {string} barrierType - The type of barrier being processed. Set to 'fallback-barrier' for fallback barrier types.
 * @returns {(null|Array<Object>)} If the barrierType is 'fallback-barrier', returns `null`. Otherwise, returns an array of objects containing component data.
 */
const getTrackingBarrierComponents = (barrierType) => {
	if (barrierType === FALLBACK_BARRIER_NAME) {
		return null;
	}

	try {
		const barrierPage = document.getElementById('barrier-page');
		// Get all components
		const components = barrierPage.querySelectorAll('[data-component]');

		const componentDataArray = [];

		// Get specific tracking data from each type of component and build the tracking object
		for (const componentNode of components) {
			const componentData = {};

			const templateName = componentNode.getAttribute('data-component');
			const componentUniqueName =
				componentNode.getAttribute('data-component-unique-name') || 'unknown';

			componentData.component_unique_name = componentUniqueName;
			componentData.component_name = `${templateName}-${componentUniqueName}`;

			switch (templateName) {
				case UNLOCK_BANNER_NAME:
					componentData.text =
						componentNode.querySelector(`#text-${UNLOCK_BANNER_NAME}-${componentUniqueName}`)
							?.innerText || undefined;
					break;

				case SUBSCRIPTIONS_OPTIONS_HEADER_NAME:
					componentData.text =
						componentNode.querySelector(
							`#text-${SUBSCRIPTIONS_OPTIONS_HEADER_NAME}-${componentUniqueName}`
						)?.innerText || undefined;
					break;

				case HERO_OFFER_NAME:
				case ARTICLE_HEADER_HERO_OFFER_NAME:
				case ARTICLE_HEADER_HERO_RADIO_OFFER_NAME:
					componentData.charge_button_text =
						componentNode.querySelector(`#charge-button`).innerText || undefined;

					if (templateName === HERO_OFFER_NAME) {
						// If theme is not set, it defaults to 'light'
						const theme = componentNode.getAttribute('data-theme') || 'light';
						componentData.theme = theme;
						componentData.component_name = `${templateName}-${theme}-${componentUniqueName}`;
					}
					break;

				case SUBSCRIPTIONS_OPTIONS_NAME:
					const title =
						componentNode.querySelector(`#title-${componentUniqueName}`)?.innerText || undefined;
					componentData.title = title;

					const buttonsString = [1, 2, 3].reduce((acc, i) => {
						// Buttons' text in SubscriptionOptions can be empty string
						const buttonText =
							componentNode.querySelector(`#button${i}-${componentUniqueName}`)?.innerText || undefined;
						// Add the text of the current button to the componentData object. Eg., 'componentData.button1_text='
						componentData[`button${i}_text`] = buttonText;
						// If the current button exists, add its number and text to the accumulator
						return buttonText ? acc + `-${i}-${buttonText}` : acc;
					}, '');

					componentData.component_name = `${templateName}-${title}${buttonsString}-${componentUniqueName}`;
					break;
			}

			componentDataArray.push(componentData);
		}

		return componentDataArray;
	} catch (e) {
		return 'getTrackingBarrierComponentsError';
	}
};

export { getTrackingBarrierComponents };
