import { getOfferIdsDisplayedOnAPage, getOfferIdFromString } from './offersUtils';

// EVENTS

/**
 * Adds an event listener for the charge button click.
 *
 * @param {Element} barrierPage - The barrier page element
 * @param {string | undefined} accessDecisionProvider - The access decision provider, e.g. ZEPHR, SUBX
 * @param {{contentClassifications: Array<string>}} - ["CONDITIONAL_STANDARD","CONDITIONAL_FT_EDIT"] Content classifications as defined by A&I APIs.
 * @param {{contentTopics: {article_main_topic: {id: string, label: string}, article_topics: Array<{id: string, label: string}>}}} - It includes the main topic and additional topics, each with an ID and label.
 */
export const addSubxClickEventListener = (
	barrierPage,
	accessDecisionProvider,
	contentClassifications,
	contentTopics
) => {
	const chargeButton = barrierPage.querySelector('#charge-button');

	chargeButton?.addEventListener('click', async () => {
		// `primaryOffersIds` is expected to be an array by the api endpoint
		// this is to ensure compatibility with other use cases where we have multiple `primaryOffersIds`
		const primaryOffersIds = [getOfferIdFromString(chargeButton.getAttribute('href'))];

		await sendCampaignData({
			// actions defined in actionsMapper in server/services/subXService.js
			action: 'click.charge',
			primaryOffersIds,
			accessDecisionProvider,
			contentClassifications,
			contentTopics,
		});
	});
};

/**
 * Polling for SubxClientEvents properties every 3 seconds
 */
async function waitForSubXClientEvents() {
	while (!window.SubxClientEvents) {
		await new Promise((resolve) => setTimeout(resolve, 3000));
	}
}

/**
 * Tracks an impression event for SubX.
 *
 * @param {Element} barrierPage - The barrier page element.
 * @param {string | undefined} accessDecisionProvider - The access decision provider, e.g. ZEPHR, SUBX
 * @param {{contentClassifications: Array<string>}} - ["CONDITIONAL_STANDARD","CONDITIONAL_FT_EDIT"] Content classifications as defined by A&I APIs.
 * @param {{contentTopics: {article_main_topic: {id: string, label: string}, article_topics: Array<{id: string, label: string}>}}} - It includes the main topic and additional topics, each with an ID and label.
 */
export const trackSubxImpression = async (
	barrierPage,
	accessDecisionProvider,
	contentClassifications,
	contentTopics,
	userInfo
) => {
	await waitForSubXClientEvents();

	// TODO: define different actions for different barriers?
	const barrierType = 'barrier';

	const { primaryOffersIds, otherOffersIds } = getOfferIdsDisplayedOnAPage(barrierPage);

	await sendCampaignData({
		// actions defined in actionsMapper in server/services/subXService.js
		action: `pageView.${barrierType}`,
		primaryOffersIds,
		otherOffersIds,
		accessDecisionProvider,
		contentClassifications,
		contentTopics,
		userInfo,
	});
};

// API
/**
 * Sends campaign data to the server.
 *
 * @param {{action: string}} - The type of action to perform.
 * @param {{primaryOffersIds: Array<string>}} - Array of primary offer IDs.
 * @param {{otherOffersIds: Array<string>}} - Array of other offer IDs.
 * @param {string | undefined} accessDecisionProvider - The access decision provider, e.g. ZEPHR, SUBX
 * @param {{contentClassifications: Array<string>}} - ["CONDITIONAL_STANDARD","CONDITIONAL_FT_EDIT"] Content classifications as defined by A&I APIs.
 * @param {{article_main_topic: {id: string, label: string}, article_topics: Array<{id: string, label: string}>}} - It includes the main topic and additional topics, each with an ID and label.
 * @param {{userInfo: {knownDate: string, isLapsed: boolean, isTrial: boolean}}} userInfo - Contains user information including:
 *   - knownDate: The date when the user registered.
 *   - isLapsed: Indicates whether the user has lapsed.
 *   - isTrial: Indicates whether the user is a trial user.
 */
export const sendCampaignData = async ({
	action,
	primaryOffersIds,
	otherOffersIds,
	accessDecisionProvider,
	contentClassifications,
	contentTopics,
	userInfo,
}) => {
	try {
		await fetch('/barrier/api/subX', {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json',
			},
			body: JSON.stringify({
				identifier: window.SubxClientEvents?.identifier,
				sessionId: window.SubxClientEvents?.sessionId,
				action,
				currentUrl: window.location.href,
				primaryOffersIds,
				otherOffersIds,
				accessDecisionProvider,
				contentClassifications,
				contentTopics,
				userInfo,
			}),
		});
	} catch (error) {
		// Fails silently, will log errors in the server side
	}
};
